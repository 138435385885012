/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.css-b62m3t-container{
    .css-1s2u09g-control{
        background-color: var(--#{$prefix}gray-200);
        border-color: var(--#{$prefix}border-color);
        &:hover {
            border-color: var(--#{$prefix}border-color);
        }
    }
    .css-1pahdxg-control{
        background-color: var(--#{$prefix}gray-200);
        border-color: var(--#{$prefix}border-color);
        box-shadow: none;
        &:hover {
            border-color: var(--#{$prefix}border-color) !important;
        }
    }
    .css-26l3qy-menu {
        background-color: $dropdown-bg;
        .css-1n7v3ny-option{
            cursor: pointer;
            background-color: $dropdown-link-hover-bg;
            &:active {
                background-color: $dropdown-link-hover-bg;
            }
        }
    }
    .css-1rhbuit-multiValue{
        background-color: var(--#{$variable-prefix}light);

    }
    .css-12jo7m5{
        color:$heading-color;
    }
    .css-6j8wv5-Input,  .css-qc6sy-singleValue{
        color:$heading-color;
    }
}


.marketplace-swiper .swiper-button-next, .marketplace-swiper .swiper-button-prev {
    top: 24px;
}


.marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 22px;
    line-height: 2;
}

.marquee div {
    display: block;
    width: 200%;
    height: 30px;
    position: absolute;
    overflow: hidden;
    animation: marquee 35s linear infinite;
}

.marquee span {
    float: left;
    width: 50%;
}

@keyframes marquee {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

input.MuiInputBase-input {
    color: #041B29;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: transparent !important;
    background-image: none !important;
}
.gradient_blue_btn {
    border: none;
    border-radius: 7px;
    background: linear-gradient(108deg, #09A5D5 0%, #006ECB 100%);
    padding: 12px 30px;
    color: #FFF;
    font-size: 1.23em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.dark_btn {
    padding: 12px 30px;
    font-size: 1.23em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.donut_holder {
    .apexcharts-legend {
        padding: 0;
    }
}
.var_sep_tab_holder {
    background: transparent;
    box-shadow: none;

    & > .card-header {
        & + .card-body {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &.blue_bg {
        & > .card-header {
            background: #1C40CA;

            .card-header-tabs {
                .nav-link {
                    color: #fff;

                    &.active {
                        background: #1C40CA;
                        color: #fff;
                        font-weight: 600;
                    }
                    &::after {
                        background: #fff;
                    }
                }
            }
        }
    }
}
.MuiFormLabel-root {
    font-size: 0.875rem !important;

    &.Mui-focused,
    &.MuiFormLabel-filled {
        font-size: 1rem !important;
    }
}
// .MuiFormLabel-filled,
// .Mui-focused {
//     & + .MuiInputBase-root {
//         fieldset {
//             legend {
//                 font-size: 0.875rem !important;
//             }
//         }
//     }
// }
.MuiMenu-paper .MuiMenu-list {
    max-height: 200px;
}
.multiselect_outer_holder {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}
.multiselect_outer_holder.Mui-focused {
    border: 1px solid #1976d2;
    border-radius: 4px;
}
.multiselect_outer_holder fieldset {
    border: none;
}
.multiselect_inner_holder {
    max-height: 100px;
    overflow: auto;
}
.multiselect_inner_holder > div {
    max-height: 100%;
    overflow: auto;
}
.MuiFormLabel-filled {
    padding-left: 2px !important;
    left: -4px !important;
}
input.MuiInputBase-input,
.MuiSelect-select {
    padding: 14px 16px !important;
}
.MuiSelect-select {
    padding-right: 32px !important;
}
.bottom_save_offcanvas {

    .simplebar_holder {
        height: calc(100% - 75px);
    }
    .fullHeight_simplebar_holder {
        height: 100%;

        .simplebar-content {
            max-height: calc(100% - 75px);
            overflow: auto;
        }
    }
    .simple_tabs_btn_btm_holder {
        height: 100%;

        .simplebar-content {
            height: 100%;
            .var_sep_tab_holder {
                height: 100%;
                .content_holder {
                    max-height: calc(100vh - 220px);
                    overflow: auto;
                }
            }
        }
    }
    .save_btm_holder {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 18px;
        background: #fff;
        border-top: 1px solid #CAD2D7;

        .btn-outline-dark {
            border-radius: 7px;
            border: 1px solid #7E8B93;
            background: linear-gradient(0deg, var(--White, #FFF) 0%, var(--White, #FFF) 100%), linear-gradient(108deg, #09A5D5 0%, #006ECB 100%);
            color: #7E8B93;

            &:hover {
                color: #7E8B93;
                border: 1px solid #7E8B93;
            }
            &:active,
            &:focus {
                border: 1px solid #7E8B93;
                border-color: #7E8B93 !important;

            }
        }
    }
}
.btn-outline-dark {
    &:active,
    &:focus {
        border: 1px solid #041B29;
        border-color: #041B29 !important;

    }
}
.gradient_blue_bg {
    border-radius: 7px;
    background: linear-gradient(108deg, #09A5D5 0%, #006ECB 100%);
}
.grey_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #7E8B93;
    background: #ECF1F5;
    color: #041B29;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.transparent_btn {
    padding: 0;
    background: transparent;
    border: none;
    &:disabled {
        background: transparent;
    }
    &.blue_transparent_btn {
        color: #00A0EA;
    }
    &.only_icon {
        font-size: 24px;

        i {
            color: #041b29;
            transform: rotate(0deg);
            transition: all 0.5s ease-in-out;
        }
    }
    .blue_outline_icon {
        color: #00A0EA !important;
        transform: rotate(180deg) !important;
        transition: all 0.5s ease-in-out !important;
    }
    &:hover,
    &:active {
        background-color: transparent !important;
        border-color: transparent !important;
    }
}
.bold_heading {
    color: #041B29;
    font-size: 1.13rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.component_holder {
    vertical-align: top;
    padding: 10px;
    min-width: 261px;
    border-radius: 5px;
    border: 1px solid #D1DCE5;
    background: var(--White, #FFF);
    box-shadow: 0px 0px 0px 0px rgba(169, 191, 209, 0.10), 0px 5px 12px 0px rgba(169, 191, 209, 0.10), 0px 22px 22px 0px rgba(169, 191, 209, 0.09), 0px 49px 29px 0px rgba(169, 191, 209, 0.05), 0px 87px 35px 0px rgba(169, 191, 209, 0.01), 0px 136px 38px 0px rgba(169, 191, 209, 0.00);
    padding: 5px 10px;
    font-size: 1.23em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.modal_heading {
    color: #041B29;
    font-size: 1.35em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
}
.dark_bg_holder {
    background: #041B29;
}
.details_user_sidebar {
    .nav-tabs-custom .nav-item .nav-link::after {
        background: #041B29;
    }
    .details_user_sidebar_info {
        padding: 8px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ECF1F5;

        .label_holder {
            width: 100%;
            color: #5A6871;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 4px;
        }
        .notification_label_holder {
            color: #5A6871;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 4px;
        }
        .info_title {
            color: #041B29;
            font-size: 1.23em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;
        }

        &.dark_bg_holder {
            .label_holder {
                width: auto;
                color: #7E8B93;
            }
            .info_title {
                color: #fff;
            }
        }
        .row {
            .col-12 {
                &:first-child {
                    border-right: 1px solid #ECF1F5;

                    @media (max-width: 576px) {
                        border-right: none;
                        border-bottom: 1px solid #ECF1F5;
                        padding-bottom: 8px;
                    }
                }
                &:last-child {
                    @media (max-width: 576px) {
                        padding-top: 8px;
                    }
                }
            }
        }
    }
}
.main_primary_btn {
    border-radius: 4px;
    background: #1C40CA;
    color: #fff;
    box-shadow: 0px 4px 8px 0px rgba(28, 64, 202, 0.30);

    &.btn-outline-primary {
        border: 1px solid #00A0EA;
        color: #00A0EA;
        background: transparent;
        box-shadow: none;
    }
    &.icon_content_btn {
        display: inline-flex;
        align-items: center;
        max-height: 37px;

        i {
            font-size: 20px;
        }
    }
}
.danger_red_btn {
    padding: 7px 15px;
    border-radius: 5px;
    background: #ED1919;
    color: #fff;
    border: 1px solid #ED1919;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        background: #ED1919;
        color: #fff;
    }
}
.vertical_scrollbar,
.multiselect_inner_holder > div {
    scrollbar-width: thin;
    scrollbar-color: #a2a3a3 #a2a3a3;
}
.vertical_scrollbar::-webkit-scrollbar,
.multiselect_inner_holder > div::-webkit-scrollbar {
    width: 5px;
}
.vertical_scrollbar:hover::-webkit-scrollbar {
    width: 8px;
}
.vertical_scrollbar::-webkit-scrollbar-track,
.multiselect_inner_holder > div::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 20px;
}
.vertical_scrollbar::-webkit-scrollbar-thumb,
.multiselect_inner_holder > div::-webkit-scrollbar-thumb {
    background-color: #a2a3a3;
    border-radius: 20px;
}
.horizontal_scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #a2a3a3 #a2a3a3;
  }
.horizontal_scrollbar::-webkit-scrollbar {
  height: 5px;
}
.horizontal_scrollbar:hover::-webkit-scrollbar {
  height: 8px;
}
.horizontal_scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 20px;
}
.horizontal_scrollbar::-webkit-scrollbar-thumb {
  background-color: #a2a3a3;
  border-radius: 20px;
}
.component_modal_listing {
    display: flex;
    align-items: flex-start;

    .heading {
        color: #041B29;
        font-size: 1.23em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .card_holder {
        display: inline-block;
        vertical-align: top;
        padding: 10px;
        min-width: 261px;
        border-radius: 8px;
        border: 1px solid #D1DCE5;
        background: var(--White, #FFF);
        box-shadow: 0px 0px 0px 0px rgba(169, 191, 209, 0.10), 0px 5px 12px 0px rgba(169, 191, 209, 0.10), 0px 22px 22px 0px rgba(169, 191, 209, 0.09), 0px 49px 29px 0px rgba(169, 191, 209, 0.05), 0px 87px 35px 0px rgba(169, 191, 209, 0.01), 0px 136px 38px 0px rgba(169, 191, 209, 0.00);
        margin: 5px;

        .each_list_holder {
            background: #FFF;
            box-shadow: 0px 3px 8px 0px #DEE9F1;
            padding: 16px 10px;
            color: #5A6871;
            font-size: 1.26em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 5px;

            .MuiCheckbox-root {
                padding: 0;
                padding-right: 10px;
            }
        }
    }
}
.flexbox_holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.modal-header {
    &.flexbox_holder {
        .modal-title {
            width: 100%;
            display: inherit;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
    }
}

//tree view css
.wtree {
    margin-left: -30px;
    ul {
        margin-left: 10px;
        padding-left: 10px;
    }
    li {
        list-style-type: none;
        margin: 10px 0 10px 10px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -10px;
            left: -20px;
            border-left: 1px solid #00a0ea;
            border-bottom: 1px solid #00a0ea;
            border-radius: 0 0 0 18px;
            width: 20px;
            height: 30px;
        }

        &::after {
            position: absolute;
            content: "";
            top: 0px;
            left: -20px;
            border-left: 1px solid #00a0ea;
            /* border-radius: 0 0 0 18px; */
            width: 20px;
            height: 100%;
        }

        &:last-child::after {
            display: none;
        }

        span.card_span {
            display: inline-block;
            min-width: 100%;
            white-space: nowrap;
            padding: 12px 20px;
            color: #5a6871;
            text-decoration: none;
            border-radius: 6px;
            border: 1px solid #d1d4d6;
            border-width: 1px 1px 1px 8px;
            background: #fff;
            box-shadow: 0px 3px 8px 0px #dee9f1;

            &.active {
                border: 1px solid #00a0ea;
                border-width: 1px 1px 1px 8px;
            }
        }
    }
    & > ul:first-child > li:before {
        content: none;
    }
}
.tree_container {
    max-height: calc(100vh - 206px);
    overflow: auto;
    .wtree {
        margin-left: -65px;
        ul {
            padding-left: 46px;
        }
        li {
            &::before {
                border-left: 2px solid #00a0ea;
                border-bottom: 2px solid #00a0ea;
                border-radius: 0 0 0 8px;
                width: 50px;
                height: 70px;
                left: -50px;
                z-index: -1;
            }
            &::after {
                width: 50px;
                border-left: 2px solid #00a0ea;
                left: -50px;
                z-index: -1;
            }
            .checkbox_holder {
                input[type="checkbox"] {
                    width: 36px;
                    height: 36px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    opacity: 0;
                    cursor: pointer;

                    & + .checkbox_container {
                        &::before {
                            content: '+';
                            font-size: 30px;
                            width: 30px;
                            height: 36px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                        }
                    }

                    &:checked {
                        & + .checkbox_container {
                            &::before {
                                content: '-';
                            }
                        }
                    }
                }
                .checkbox_container {
                    width: 36px;
                    height: 36px;
                    background: #00A0EA;
                    border-radius: 9px 0px 60px 0px;
                    position: absolute;
                }
            }
            &.list {
                .checkbox_holder {
                    display: none;
                }
            }
            span.card_span {
                padding: 20px 36px;
                border-width: 1px;

                .heading {
                    color: #041B29;
                    font-size: 1.4em;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 0;
                }

                .content_holder {
                    display: flex;
                    margin-top: 10px;

                    .each_content_holder {
                        display: inherit;
                        flex-wrap: wrap;

                        &:first-child {
                            padding-right: 36px;
                        }
                        &:not(:first-child) {
                            padding: 0 36px;
                            border-left: 1px solid #CAD2D7;
                        }
                        label {
                            width: 100%;
                            color: #7E8B93;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 0;
                        }
                        p {
                            color: #041B29;
                            font-size: 1.23em;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 0;
                        }
                        .flex_holder {
                            display: inherit;
                        }
                    }
                }
            }
        }
    }
}
  /* .wtree li span:hover,
  .wtree li span:focus {
    background: #aaa;
    color: #000;
    border: 1px solid #aaa;
  }
  .wtree li span:hover + ul li span,
  .wtree li span:focus + ul li span {
    background: #eee;
    color: #000;
    border: 1px solid #aaa;
  }
  .wtree li span:hover + ul li:after,
  .wtree li span:focus + ul li:after,
  .wtree li span:hover + ul li:before,
  .wtree li span:focus + ul li:before {
    border-color: #aaa;
  } */

  .accordion_add_user_holder {
    .accordion-item {
        .accordion-header {
            .accordion-button {
                padding-left: 0;
                padding-right: 0;
                border: 1px solid #CAD2D7;
                border-width: 1px 0 1px 0;
                position: relative;

                &::after {
                    transform: rotate(-90deg);
                }
                &:not(.collapsed) {
                    background: transparent;
                    box-shadow: none;
                    &::after {
                        transform: rotate(0deg);
                    }
                }
                .add_btn {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .accordion-collapse {
            .accordion-body {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
  }
.all_users_holder {
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #ECF1F5;
            border-bottom: 1px solid #ECF1F5;
            padding: 10px 0;

            .name {
                color: #041B29;
                font-size: 1.23em;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .designation {
                color: #7E8B93;
                font-size: 0.93em;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}
.input_end_icon_holder {
    position: relative;

    i {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }
    input {
        width: 100%;
        padding: 14px 16px;
        padding-right: 30px;
        border-radius: 5px;
        border: 1px solid #9AD9F7;
        &:focus {
            box-shadow: 0px 0px 0px 0px rgba(38, 174, 232, 0.03), 0px 2px 5px 0px rgba(38, 174, 232, 0.03), 0px 9px 9px 0px rgba(38, 174, 232, 0.03), 0px 21px 12px 0px rgba(38, 174, 232, 0.02), 0px 36px 15px 0px rgba(38, 174, 232, 0.00), 0px 57px 16px 0px rgba(38, 174, 232, 0.00);

            & + i {
                color: rgba(28, 64, 202, 1);
            }
        }
    }
}

//heading
.heading-4 {
    color: #041B29;
    font-size: 1.385em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
}

.cstm_autocomplete {
    .MuiOutlinedInput-root {
        .MuiInputBase-input {
            padding: 5px 0 !important;
            padding-left: 5px !important;
        }
    }
}
.flatpickr_with_icon {
    position: relative;
    z-index: 10;

    input {
        padding: 15px;
        padding-right: 40px;
        background-color: transparent !important;
        position: relative;
        z-index: 2;
    }
    input::placeholder {
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-size: 0.875rem !important;
        color: rgba(0, 0, 0, 0.6);
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
    }
    .icon_btn {
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: none;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 2;

        i {
            font-size: 20px;
            width: 24px;
            height: 24px;
            color: #7e8b93;
        }
    }

    &.no_bg_color {
        input {
            background: transparent;
        }
    }
    .placeholder_span {
        position: absolute;
        left: 15px;
        top: 15px;
    }
}
.timeline_holder {
    .list_holder {
        padding: 0;
        margin-top: 11px;

        li {
            display: flex;
            margin: 10px 0;
            position: relative;

            .name_initials_holder {
                min-width: 42px;
                min-height: 42px;
                max-width: 42px;
                max-height: 42px;
                border-radius: 100%;
                background: #eee;
                color: #000;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 1.1em;
                font-weight: 700;
            }
            .full_content_holder {
                padding-left: 17px;
                .name_holder {
                    color: #272C28;
                    font-size: 1.23em;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 4px;
                    padding-top: 8px;
                }
                .time_holder {
                    color: #808191;
                    font-size: 0.77em;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 5px;
                }
                .content {
                    color: #272C28;
                    font-size: 1.1em;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 9px;

                    .blue_txt {
                        color: #00A0EA;
                    }
                }
            }

            &::after {
                content: '';
                width: 1px;
                height: 90%;
                border-left: 1px dashed rgb(179, 179, 189);
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: -1;
            }
        }
    }
}
.roles_list_holder {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #00a0ea;
    border-radius: 5px;

    &:first-child {
        border: none;
        border-radius: 0;
        li {
            .inner_li_holder {
                border: 1px solid #00a0ea;
                border-radius: 5px;
                margin-bottom: 8px;
            }
        }
    }
    &:not(:first-child) {
        margin-left: 32px;
        padding: 10px 12px;
        margin-bottom: 4px;
    }

    li {
        .inner_li_holder {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            // border-radius: 5px;
            background: #fff;
            border-top: 1px solid #ECF1F5;
            // margin: 5px 0;

            .heading-4 {
                color: #7E8B93;
            }

            i {
                font-size: 18px;
                color: #7E8B93 !important;

                &.ri-arrow-down-s-line {
                    transform: rotate(-90deg);
                }
            }
            &.active {
                border-radius: 5px;
                background: #00A0EA;

                .heading-4,
                i {
                    color: #fff !important;
                }
            }
        }
    }
}
.roles_wtree {

    .main_ul {
        display: inline-block;
        min-width: calc(100% - 10px);
        // min-width: 100%;
        // margin-left: 35px;
        &:first-child {
            li {
                span.card_span {
                    margin-bottom: 8px;
                }
            }
        }
        &:not(:first-child) {
            border: 1px solid #00a0ea;
            border-radius: 5px;
            margin-left: 35px;
            margin-bottom: 8px;
            // padding-left: 35px;
            padding-right: 35px;

            &::before {
                content: '';
                position: absolute;
                left: 10px;
                top: 0;
                width: 1px;
                height: calc(50% + 20px);
                border-left: 1px solid #00a0ea;
            }
            &::after {
                content: '';
                position: absolute;
                left: 10px;
                top: calc(50% + 20px);
                width: 25px;
                height: 30px;
                border-left: 1px solid #00a0ea;
                border-bottom: 1px solid #00a0ea;
                border-radius: 0 0 0 18px;
            }
        }

        li {
            &::before {
                content: none;
            }
            &::after {
                content: none;
            }
            span.card_span {
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                border-width: 1px;
                position: relative;
                z-index: 1;

                &.active {
                    border-radius: 5px;
                    background: #00A0EA;

                    .heading-4,
                    i {
                        color: #fff !important;
                    }
                    .heading-holder  {
                        label, span {
                            color: #fff !important;
                        }
                    }
                    i.ri-arrow-down-s-line {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
    > ul.main_ul:first-child > li::before  {
        content: none;
    }
}
.checked_list_holder {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        .inner_li_holder {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            border-radius: 5px;
            background: #fff;
            border-top: 1px solid #ECF1F5;
            box-shadow: 0px 0px 0px 0px rgba(169, 191, 209, 0.10), 0px 5px 12px 0px rgba(169, 191, 209, 0.10), 0px 22px 22px 0px rgba(169, 191, 209, 0.09), 0px 49px 29px 0px rgba(169, 191, 209, 0.05), 0px 87px 35px 0px rgba(169, 191, 209, 0.01), 0px 136px 38px 0px rgba(169, 191, 209, 0.00);
            // margin: 5px 0;

            .heading-4 {
                color: #5A6871;
            }

            i {
                font-size: 24px;
                color: #05BC0C !important;
            }

            &.active {
                background: #00A0EA;

                .heading-4 {
                    color: #fff !important;
                }
            }
        }
    }
}
.checked_outer_holder {
    max-height: calc(100vh - 294px);
    overflow: auto;
    padding-right: 10px;

    .checked_card_holder {
        .heading {
            color: #041B29;
            font-size: 1.385em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .textarea_holder {
            .MuiInputBase-root {
                &::before,
                &::after {
                    content: none;
                }
            }
            input[type="textarea"] {
                border-radius: 5px;
                border: 1px solid #7E8B93;
            }
        }
        .btn_holder {
            padding: 20px 0 0;
            margin-top: 20px;
            border-top: 1px solid #CAD2D7;

            .transparent_btn {
                display: inline-flex;
                align-items: center;
                color: #5A6871;
            }
        }
    }
}
.main_page_heading_holder {
    flex-wrap: wrap;
}
.header_container_btns {
    border-top: 1px solid #CAD2D7;
    border-bottom: 1px solid #CAD2D7;
    padding: 6px 0;
    margin-bottom: 12px;

    .btns_holder {
        button {
            position: relative;
            margin: 2px 20px 2px 2px;
            &:not(:last-child) {
                &::after {
                    content: '';
                    width: 1px;
                    height: 100%;
                    border-right: 1px solid #D6D8E3;
                    position: absolute;
                    top: 0;
                    right: -10px;
                }
            }
        }
        .btn-group {
            .dropdown {
                button {
                    margin-right: 2px;
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}
.roles_card_holder {
    .form_holder {
        border-radius: 6px;
        background: #F4F7F9;
        padding: 20px;
    }
    .accordion_holder {
        max-height: 35vh;
        overflow: auto;
        .accordion {
            .accordion-header {
                .accordion-button {
                    i {
                        font-size: 24px;
                    }
                    &::after {
                        transform: rotate(-90deg);
                    }
                    &:not(.collapsed) {
                        background: transparent;
                        box-shadow: none;
                        &::after {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
            .accordion-body {
                padding-top: 0;
                .permission_ul {
                    list-style-type: none;
                    padding: 10px 20px;
                    margin: 0;
                    border-radius: 8px;
                    border: 1px solid #DFDFDF;
                    background: #FFF;

                    li {
                        display: flex;
                        justify-content: space-between;
                        padding: 16px 0;

                        &:not(:last-child) {
                            border-bottom: 1px solid rgb(227, 227, 277);
                        }

                        .checkbox_holder {
                            display: flex;
                            align-items: center;
                            .MuiCheckbox-root {
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                            .checkbox_label {
                                color: #041B29;
                                font-size: 1.23em;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin: 0;
                            }
                        }
                        .transparent_btn {
                            &.only_icon {
                                i {
                                    color: #7E8B93;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .multiview_cardheader {
        border-radius: 5px 5px 0px 0px;
        background: #00A0EA;
        color:  #FFF;
        font-size: 18px;
        font-weight: 600;

        .transparent_btn.only_icon i,
        h4 {
            color: #fff;
            margin: 0;
        }
    }
}
.outlined_yellow_btn {
    border-radius: 16px;
    border: 1px solid #E79E12;
    background: #FFF;
    color: #E79E12;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
        background: #E79E12;
        color: #fff;
    }
}
.no_header_title_modal {
    .modal-header {
        .modal-title {
            justify-content: flex-end;
        }
    }
}
.delete_confirmation_modal {
    .modal_body {
        text-align: center;

        .heading_holder {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #EEA619;
            font-size: 1.85em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            .green_txt {
                color: #00A642;
            }
        }
        .content_holder {
            color: #041B29;
            font-size: 1.23em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 30px 0;
        }
    }
}
.back_arrow {
    i {
        font-size: 24px;
        color: #041B29;
    }
}
.black_icon_anchor {
    i {
        font-size: 20px;
        color: #041B29;
    }
}
.attachment_btn_holder {
    display: inline-flex;
    align-items: center;
    color: #041B29;
    font-size: 1.23em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    background: #ECF1F5;
    padding: 8px 10px;
    border: none;
    position: relative;

    &:hover {
        background-color: #ecf1f5;
        color: #041B29;
    }

    input[type="file"] {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        cursor: pointer;
    }
}

.menu_item_holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    align-items: flex-start !important;
    border-bottom: 1px solid rgb(202, 210, 215) !important;
    padding: 10px !important;

    .desgination_holder {
        color: #041B29;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 2px 6px;
        border-radius: 4px;
        background: #D4F1FF;
    }
}
.full_white_modal {
    .modal-content {
        background: #fff;
    }
}
.modal {
    .qrcode_holder {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .content {
            color: #041B29;
            text-align: center;
            font-size: 1.23em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .heading {
            color: #041B29;
            font-size: 1.5em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .large_heading {
            font-size: 1.85em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}
.text_success {
    color: #00B448 !important;
}
.text_red {
    color: #FF5A5A !important;
}
.text-blue {
    color: #1C40CA !important;
}
.without_brdr_btn {
    border: none;
}
.input_withicon_holder {
    position: relative;

    .MuiInputBase-input {
        padding-left: 40px !important;
    }
    i {
        font-size: 24px;
        position: absolute;
        left: 10px;
        top: 8px;
    }
    label.MuiInputLabel-formControl {
        color: #7E8B93;
        font-family: 'Poppins', sans-serif;
        // font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0 !important;
        left: 25px;

        &.Mui-focused,
        &.MuiFormLabel-filled {
            left: -3px;
        }
    }
    // fieldset {
    //     legend,
    //     legend span {
    //         color: #7E8B93;
    //         font-family: 'Poppins', sans-serif;
    //         // font-size: 9px;
    //         font-style: normal;
    //         font-weight: 400;
    //         line-height: normal;
    //         transition: none;
    //     }
    // }
    &.right_side_icon {
        .MuiInputBase-input {
            padding-left: 16px !important;
            padding-right: 40px !important;
        }
        i {
            left: auto;
            right: 10px;
            top: 10px;
            color: #5A6871 !important;
            font-size: 20px !important;
        }
        label.MuiInputLabel-formControl {
            left: 0;
        }
        .Mui-focused {
            fieldset {
                legend,
                legend span {
                    padding: 0 5px;
                }
            }
        }
    }
}
.margin-b-20 {
    margin-bottom: 20px;
}

.outer_calendar_table {
    position: relative;

    .calendar_table {
        width: 100%;
        // height: 415px;
        overflow: hidden;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }
        .arrow_holder {
            width: 100%;

            .btn {
                position: absolute;
                top: 10px;
                z-index: 10;
                background: #041B29;
                border: none;
                padding: 0;

                @media (max-width: 1200px) {
                    top: 8px;
                }
            }
            .left_arrow_btn {
                left: 11%;
            }
            .right_arrow_btn {
                right: 4%;
            }
        }

        .table {
            // display: table;
            // table-layout: fixed;
            border-collapse: collapse;
            margin-bottom: 0;

            .each_table_row {
                // display: table-row;
                display: flex;
                border: 1px solid #ECF1F5;
                &:first-child {
                    .each_table_data {
                        background: #041B29;
                        font-size: 1.23em;
                        color: #ECF1F5;
                        position: sticky;
                        top: 0;
                        z-index: 1;

                        @media (max-width: 1200px) {
                            font-size: 0.87em;
                        }

                        &:first-child {
                            z-index: 2;
                        }
                    }
                }
                .each_table_data {
                    display: inherit;
                    align-items: center;
                    min-width: 12.5%;
                    max-width: 12.5%;
                    padding: 8px 10px;
                    background: #fff;
                    color: #7E8B93;
                    font-size: 1.08em;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &:first-child {
                        position: sticky;
                        left: -1px;
                        top: 0;
                        max-width: 150px;
                    }
                    .anchor_holder {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .each_schedule_data {
                        display: inline-flex;
                        flex-wrap: wrap;
                        align-items: center;
                        padding: 0;
                        border-radius: 4px;
                        border: 1px solid #CAD2D7;
                        background: #FFF;

                        .each_data_holder {
                            padding: 2px;
                            border-radius: 2px;
                            margin: 2px;
                            text-align: center;

                            i {
                                font-size: 12px;
                            }
                            .info_data {
                                color: #041B29;
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 12px;
                                margin-bottom: 0;
                            }
                            &.shift {
                                background: #D8F6FF;

                                i {
                                    color: #1C40CA;
                                }
                            }
                            &.question {
                                background: #ECF1F5;

                                i {
                                    color: #5A6871;
                                }
                            }
                            &.warning {
                                background: #FFE5E5;

                                i {
                                    color: #EE3D3D;
                                }
                            }
                            &.survey {
                                background: #CDFFC1;

                                i {
                                    color: #197602;
                                }
                            }
                            &.survey_line {
                                background: #FFEFD0;

                                i {
                                    color: #D38D06;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.stat_card {
    padding: 4px 10px;
    border-radius: 5px;

    .top_holder {
        display: flex;
        align-items: center;

        i {
            font-size: 20px;
        }

        .info_holder {
            color: #041B29;
            font-size: 1.23em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 8px;
        }
    }
    .title_holder {
        color: #7E8B93;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
    }
    &.stat_md_card {
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        display: inline-block;

        .info_holder {
            color: #041B29;
            font-size: 12px;
            font-weight: 400;
        }
    }
}
.form_details_card_holder {
    border-radius: 0 0 10px 10px;
    .card-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        background: #00A0EA;
        color: #fff;
        font-size: 1.08em;
        border-radius: 10px 10px 0 0;

        &.white_bg {
            background: #fff;

            .heading {
                color: #041B29;
                font-size: 1.29em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .btn {
                background: #00A0EA;

                i {
                    font-size: 18px;
                    color: #fff;
                }
            }
        }
    }
    .card-body {
        padding: 0 1rem;
    }
    .scrollable_card_holder {
        display: flex;
        overflow: auto;
    }
    .each_data_holder {
        padding: 10px 16px;
        white-space: nowrap;
        &:not(:last-child) {
            border-right: 2px solid #ecf1f5;
        }
        .label_holder {
            color: #7E8B93;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;
        }
        .content_holder {
            color: #041B29;
            font-size: 1.08em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;
        }
    }
}

.cstm_acc_holder {
    .accordion_header,
    .accordion-header .accordion-button {
        background: #041B29;
        padding: 8px 30px;
        box-shadow: none;

        h4 {
            color: #FFF;
            font-size: 1.39em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0;
        }
    }
    .accordion_header .accordion-button {
        padding: 0;

        &::after {
            filter: brightness(0) invert(1);
        }
    }
    .another_details_holder {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #e5e5e5;

        .accordion_header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
    }
    &.light_blue_accordion {
        .accordion_header,
        .accordion-header .accordion-button {
            background: #C6EDFF;

            h4 {
                color: #1C40CA;
            }
        }
        .accordion_header .accordion-button {

            &::after {
                filter: none;
            }
        }
    }
}
.full_rounded_tab_holder {
    display: inline-flex;
    border-radius: 20px;
    border: 1px solid #C5E3F6;
    background: #FFF;
    box-shadow: 0px 6px 12px 0px rgba(0, 160, 234, 0.16);

    li {
        border-radius: 20px;
        cursor: pointer;

        a {
            border-radius: 20px !important;
            padding: 8px 24px !important;
            color: #7E8B93 !important;

            &.active {
                background-color: #00A0EA !important;
                border-top-left-radius: 20px !important;
                border-top-right-radius: 20px !important;
                color: #fff !important;
            }

            &:hover {
                border-radius: 20px;
            }
        }
    }
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; /* Adjust the max-width to your preference */
}
.text-wrap {
    max-width: 250px; /* Adjust the max-width to your preference */
    word-wrap: break-word;
}
.grey_holder {
    border-radius: 6px;
    background: #ECF1F5;
    padding: 10px;

    input,
    textarea,
    select,
    .MuiFormControl-root  {
        background: #fff;
    }
}
.brdr-top {
    border-top: 1px solid #CAD2D7;
}
.brdr-btm {
    border-bottom: 1px solid #CAD2D7;
}
.card_day_holder {
    position: relative;
    input[type="radio"] {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;

        &:checked {
            & + label {
                background: #00A0EA;
                color: #fff;
            }
        }
    }
    .day_label {
        width: 40px;
        height: 40px;
        display: flex;
        padding: 8px 15px;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: #ECF1F5;
        margin: 5px;
    }
}
.filter_badge_holder {
    display: inline-flex;
    align-items: center;

    .each_holder {
        padding: 3px 10px;
        border-radius: 13px;
        border: 1px solid #A8C4DB;
        background: #DBE6EF;
        color: #1C40CA;
        font-size: 1.1em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 5px;

        &:last-child {
            margin-right: 20px;
        }
    }
}
.blueoverlay_table {
    thead {
        th {
            &:nth-last-child(-n+3) {
                background: rgb(16, 58, 84);
            }
        }
    }
    tbody {
        td {
            &:nth-last-child(-n+3) {
                background: #E0F5FF;
            }
        }
    }
}
.no_result_holder {
    max-width: 376px;
    margin: 40px auto 20px;
    text-align: center;

    .content {
        color: #5A6871;
        font-size: 1.1em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 17px 0 0;
    }
}
.white_checkbox {
    svg {
        path {
            fill: #fff;
        }
    }
}
.user_name_holder {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #dcdcdc;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
}
.attachment_container {
    position: relative;

    input[type="file"] {
        position: relative;
        z-index: 1;
        opacity: 0;
    }
    .grey_btn {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
}
.attachment_files_holder {
    display: flex;
    flex-wrap: wrap;
    .each_file_holder {
        margin: 0 10px 10px 0;
        text-align: center;
        position: relative;
        background: #eeeeee;
        padding: 10px;
        border-radius: 5px;

        img {
            width: 40px;
        }
        .filename {
            display: inline-block;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        i {
            font-size: 15px;
        }
        .anchor_holder {
            display: inline-flex;
            align-items: center;
        }
        .transparent_btn {
            display: inline-flex;
            align-items: center;
            position: absolute;
            right: 5px;
            top: 0;
            i {
                color: #FF5A5A;
                font-weight: 500;
            }
        }
    }
}
.underlined_anchor {
    text-decoration: underline !important;
}
.bordered_txt {
    display: inline-block;
    border: 1px solid #00A0EA;
    border-radius: 7px;
    padding: 12px 30px;
    color: #00A0EA;
    font-size: 1.23em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.faq_container {
    &.component_modal_listing {
        .card_holder {
            margin: 0;
            padding: 0;
            border-radius: 0;

            .each_list_holder {
                box-shadow: none;
                padding: 0;
                margin: 0;
                font-size: 1.23em;
                cursor: pointer;

                > div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px;
                    margin: 0;
                    color: #000;
                    cursor: pointer;

                    &.active {
                        background: #00A0EA;
                        color: #fff;

                        .transparent_btn {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}
table {
    th.sortable-header {
        &.not-sorted {
            span.d-inline-flex {
                color: #fff !important;
            }
        }
        span.d-inline-flex {
            display: inline-block !important;
            position: relative;
            height: 100%;
            color: #00A0EA !important;

            i {
                position: absolute;
                left: 0;
                width: 10px;
                height: 10px;

                &.ri-arrow-up-s-fill {
                    top: -15px;
                }
                &.ri-arrow-down-s-fill {
                    top: -10px;
                }
            }
        }
    }
}

.str-chat__channel-list {
    width: 30%;
}
.str-chat__channel {
    width: 100%;
}
.str-chat__thread {
    width: 45%;
}

.str-chat-header-livestream {
    width: 100%;
    height: 70%;
}

.str-chat_list {
    height: calc(100vh-70vh);
}

.str-chat__input-flat_wrapper {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.users-list {
    background: #ffffff;
    padding: 20px;
    padding-left: 30px;
    border-radius: calc(16px / 2) 16px 0 0;
    border: 1px solid #ecebeb;
}
.truncate_text {
    max-width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.name_initials_holder {
    width: 108px;
    height: 108px;
    border-radius: 100%;
    background: linear-gradient(0deg, #F9DC78 0%, #F9DC78 100%),
    lightgray 50% / cover no-repeat;
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    border: 5px solid #FFF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.table-light {
    thead {
        tr {
            th {
                background: #ECF1F5;
            }
        }
    }
    tbody {
        tr {
            td {
                background: #fff;
            }
        }
    }
}
.each_table_container {
    text-align: left;
    margin-bottom: 12px;

    .dark_heading_holder {
        background: #041B29;
        padding: 6px 10px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left;
    }

    .table-responsive {
        max-height: 200px;
    }
    table {
        thead {
            tr {
                th {
                    position: sticky;
                    top: 0;
                }
            }
        }
    }
}

.calendar_tooltip_holder {
    display: none;
    border-radius: 4px;
    border: 1px solid #CAD2D7;
    background: #ECF1F5;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 17px 17px 0px rgba(0, 0, 0, 0.04), 0px 39px 23px 0px rgba(0, 0, 0, 0.03), 0px 69px 28px 0px rgba(0, 0, 0, 0.01), 0px 108px 30px 0px rgba(0, 0, 0, 0.00) !important;
    padding: 10px;
    position: absolute;
    top: 100%;

    .each_holder {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      font-style: normal;
      line-height: normal;

      .label_holder {
        color: #041B29;
        font-weight: 400;
        margin-bottom: 0px;
      }
      .info_holder {
        color: #1C40CA;
        font-weight: 500;
        padding-left: 2px;
      }
    }
    &::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: -10px;
      left: 10px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 11px solid #CAD2D7;
    }
  }

.outer_calendar_table {
    .calendar_table {
        .table {
            .each_table_row {
                .each_table_data {
                    .each_schedule_data {
                        &:hover {
                            & + .calendar_tooltip_holder {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom_modal_width {
    max-width: 700px;
    width: 100%;
}

.grid-holder {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 8px;
}

.upload_holder {
    position: relative;
    border: 1px dashed #ccc;
    border-radius: 8px;
    background: #ECF1F5;
    padding: 10px;
    width: 100%;

    input[type="file"] {
        position: absolute;
        width: 95px;
        z-index: 1;
        opacity: 0;
    }
    .each_file_holder {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        margin: 0;
    }
}

.nav-tabs-custom {
    &.nav-tab-btn {
        .nav-item {
            .nav-link {
                color: #1C40CA;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding: 9px 20px;
                margin: 5px;
                border-radius: 4px 4px 0px 0px;
                border: 1px solid #1C40CA;
                &.active {
                    background: #1C40CA;
                    color: #fff;
                    font-style: normal;
                    font-weight: 600;

                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}

.schedule_setting_holder {
    vertical-align: top;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #D1DCE5;
    background: var(--White, #FFF);
    padding: 5px 10px;
    font-size: 1.12em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.custom-handle { width: 2px; height: 100%; background-color: #aaa; position: absolute; right: 0; top: 0; cursor: col-resize; }
.cstm_modal_popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 10000;

    &.cstm_show_modal_popup {
        opacity: 1;
        visibility: visible;
        transform: scale(1.0);
        transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
    }

    .cstm_modal_popup_content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 15px;
        border-radius: 10px;

        .cstm_modal_popup_heading {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            color: #041B29;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 20px;
        }
    }

    &.two_sided_modal {
        .cstm_modal_popup_content {
            padding: 0;
            overflow: hidden;

            .two_container_holder {
                display: flex;

                .left_holder, .right_holder {
                    height: calc(100vh - 200px);
                }
                .left_holder {
                    width: 470px;
                    padding: 15px;

                    .procedure_outer_holder {
                        height: calc(100vh - 340px);
                        overflow: auto;

                        .each_table_data_holder {
                            .full_label_holder {
                                padding: 10px;
                                margin: 10px 0;
                                border-radius: 10px;
                                cursor: pointer;
                                label {
                                    cursor: pointer;
                                    margin-bottom: 0;
                                }
                                &.active {
                                    background: #9ac3d4;
                                }
                            }
                            input {
                                display: none;
                            }
                            input:checked + .full_label_holder {
                                background: #F2FBFF;
                            }
                            .preview_btn {
                                // display: none;
                                display: inline-flex;
                                visibility: hidden;
                            }
                            &:hover {
                                .full_label_holder {
                                    background: #F2FBFF;

                                    &.active {
                                        background: #9ac3d4;
                                    }
                                }
                                .preview_btn {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
                .right_holder {
                    width: 0;
                    transform: translateX(-100%);
                    opacity: 0;
                    transition: transform 0.5s ease-in-out, width 0.5s ease-in-out;

                    &.show {
                        width: 470px;
                        transform: translateX(0);
                        opacity: 1;
                        transition: transform 0.5s ease-in-out, width 0.5s ease-in-out, opacity 1.5s ease-in-out;
                    }

                    .cstm_modal_popup_heading {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 15px;
                        background: #00A0EA;
                        color: #fff;

                        i {
                            cursor: pointer;
                        }
                    }
                    .right_content_holder {
                        padding: 0 15px 0 0;
                        height: calc(100% - 85px);
                        overflow: auto;

                        .each_qstn_holder {
                            padding: 10px 5px;
                        }
                    }
                }
            }
        }
        .btn_holder {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            text-align: center;
            padding: 15px;
            border-top: 1px solid #CAD2D7;
        }
    }
}
.procedure_outer_holder {

    .each_table_data_holder {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ECF1F5;

        label {
            display: inline-flex;
            align-items: center;

            .id_holder {
                display: inline-block;
                padding: 6px 12px;
                border-radius: 6px;
                background: #ECF1F5;
                color: #000;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .heading_holder {
                width: 225px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #041B29;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0;
            }
            .content_holder {
                width: 225px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.desc_header {
    width: 500px;
    min-width: 500px;
    max-width: 500px;
}

.desc_tabledata_holder {
    width: 150px;
    min-width: 100%;
    max-width: 100%;
    max-height: 60px;
    overflow: auto;
    white-space: normal;
}

.thin_scrollbar {
    overflow: auto;
}
.thin_scrollbar::-webkit-scrollbar {
  width: 6px;
}
.thin_scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 20px;
}
.thin_scrollbar::-webkit-scrollbar-thumb {
  background-color: #a2a3a3;
  border-radius: 20px;
}
.form-check-template {
    text-align: center;
    position: relative;
    margin-top: 20px;
    font-size: 1.12em;
    font-style: normal;
    color: #6b6b6b;
    line-height: normal;
}
.chip-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    min-width: 0;
    max-width: 100%;
}

.primary_cstm_btn {
    background: #00a0ea;
    color: #fff;
    border-radius: 6px;
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    i {
        color: #fff;
        font-size: 22px;
        cursor: pointer;
    }
}

.procedure-success-msg {
    color: #00B448;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    display: inline-flex;

    i {
        color: #9df8c2;
    }
}
.resizeable_table {
    thead {
        th {
            padding: 0 !important;
            vertical-align: middle;
            background-color: transparent;
            .react-resizable {
                min-width: 100%;
                padding: 0 3px;
            }
            .heading_holder {
                padding: 12px 9.6px;
            }
            &.heading_holder {
                padding: 12px 9.6px !important;
            }
            .custom-handle {
                right: -1px;
            }
        }
    }
    tbody {
        tr {
            &.active {
                background-color: #f3f6f9;
            }
            td {
                cursor: pointer;
                &.no-pointer {
                    cursor: initial;
                }
            }
        }
    }

    tbody {
        tr {
            &.active {
                background-color: #f3f6f9;
            }
            td {
                span {
                    cursor: initial;
                }
                &.no-pointer {
                    cursor: initial;
                }
            }
        }
    }
}
.cstm_padding_table {
    th:first-child,
    td:first-child {
        padding-left: 9.6px;
    }
}
.stat_dashboard_cards_holder {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(220px, 2fr));

    .each_card_holder {
        // background: #f2fbff;
        color: #000;
        border-radius: 8px;
        border: 1px solid #CDDBE3;
        padding: 13px 14px;

        .top_holder {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            // margin-bottom: 10px;

            i, .icon {
                font-size: 26px;
                width: 32px;
                height: 32px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }
            svg.icon {
                width: 26px;
            }
            .status_txt {
                font-size: 16px;
                color: #041b29;
                padding-left: 5px;
            }
            .num_holder {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0;
            }
        }
    }
}
.date_select_holder {
    position: relative;
    .dropdown_holder {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        color: #1C40CA;

        i.ri-calendar-line {
            font-size: 16px;
            color: #1C40CA;
        }
        i.ri-arrow-down-s-line {
            color: #000;
        }
    }
    .flatpickr-input {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        padding: 0;
        opacity: 0;
    }
}
.procedure_acc_holder {
    .attachment_files_holder {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));

        .each_file_holder {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            img {
                height: 140px;
                object-fit: contain;

                &.img_holder {
                    width: 100%;
                }
            }
            p {
                width: 100%;
            }
        }
    }
}

.rfu-file-previewer ol li a[download] > div {
    width: auto
}

.str-chat__channel-search-container.inline {
    overflow: auto;
    height: 250px;
}

// .header-title {
//     padding: 5px 7px;
// }

// .header-pound {
//     color: #006cff;
//     font-weight: 800;
//     padding-right: 2px;
// }

// .str-chat__message-attachment--image img {
//     height: auto;
// }

.loader-alignment {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.card {
    border-radius: 10px;
    margin-bottom: 14px;

    .card-header {
        border-radius: 10px;
        .card-title {
            font-weight: 400;
            color: #041B29;
        }
    }

    &.no_header_border {
        .card-header {
            border-bottom: none;
            padding-bottom: 10px;
        }
        .card-body {
            padding-top: 0;
        }
    }
}
