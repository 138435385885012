// 
// _footer.scss
// 

.footer {
    bottom: 0;
    // padding: 20px calc(#{$grid-gutter-width} * 0.5);
    padding: 10px 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: $footer-color;
    left: $vertical-menu-width;
    text-align: right;
    z-index: 1;

    & > .dropdown {
        background: #fff;
        border-radius: 20px 20px 0 20px;
        filter: drop-shadow(-4px -4px 4px rgba(0, 0, 0, 0.25));

        & > button {
            width: 48px;
            height: 48px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            & > i {
                font-size: 25px;
                color: rgb(126, 139, 147);
            }
        }
    }

    @media (max-width: 991.98px) {
        left: 0;
    }
}

[data-layout="vertical"] {

    &[data-sidebar-size="sm"],
    &[data-sidebar-size="sm-hover"] {
        .footer {
            left: $vertical-menu-width-sm;

            @media (max-width: 767.98px) {
                left: 0;
            }
        }
    }
    &[data-sidebar-size="md"] {
        .footer {
            left: $vertical-menu-width-md;

            @media (max-width: 991.98px) {
                left: 0;
            }
        }
    }

}

[data-layout="horizontal"]{
    .footer {
        left: 0 !important;
    } 
}

[data-layout="vertical"][data-layout-style="detached"] {

    @media (min-width: 1024.1px) {
        .footer {
            left: 0 !important;
            background-color: transparent;
        } 
    }
}

[data-layout="twocolumn"] { 
    .footer {
        @media (min-width: 768.1px) {
            left: calc(#{$twocolumn-menu-iconview-width} + #{$twocolumn-menu-width});
        }
    }
}